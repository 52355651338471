body,
html,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
  background-color: #F9FAFC;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}

main {
  flex-grow: 1;
}

.widgets-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  color: #3c6d71;
  flex-direction: row-reverse;
}

.widgets-container > div {
  flex: 1 1 calc(20% - 20px);
  margin: 10px;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .widgets-container > div {
    flex: 1 1 calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  .widgets-container {
    flex-direction: column;
  }
  .widgets-container > div {
    flex: 1 1 100%;
  }
}

.image-container {
  position: relative;
  width: 100%;
  height: 400px;
  z-index: 1;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(30%);
}

@media (max-width: 768px) {
  .image-container {
    height: 300px;
  }
}

@media (max-width: 480px) {
  .image-container {
    height: 200px;
  }
}

.content-container {
  text-align: center;
  margin: 20px;
}

.content-container h1 {
  color: #3c6d71;
  font-size: calc(2vw + 2vh);
  margin-bottom: 10px;
}

.content-container hr {
  border: 0;
  height: 2px;
  background-color: #3c6d71;
  width: 50%;
  margin: 0 auto 20px auto;
}

.content-container p {
  color: #333;
  font-size: 1.2em;
  line-height: 1.5;
}

.form-container {
  width: 80%;
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Droid Arabic Kufi';
}

.form-container h2 {
  color: #3c6d71;
  font-size: 1.5em;
  text-align: center;
  margin-bottom: 10px;
}

.form-container hr {
  border: 0;
  height: 2px;
  background-color: #3c6d71;
  width: 50%;
  margin: 0 auto 20px auto;
}

.form-container form {
  display: flex;
  flex-direction: column;
}

.form-container label {
  margin-bottom: 10px;
  font-size: 1em;
  color: #333;
  text-align: right;
  display: block;
}

.form-container input,
.form-container textarea {
  width: 100%;
  padding: 10px 5px;
  margin-top: 5px;
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
  font-size: 1em;
  box-sizing: border-box;
  outline: none;
  text-align: right;
  direction: rtl;
}

.form-container textarea {
  resize: vertical;
  height: 100px;
}

.form-container button {
  padding: 10px;
  color: #fff;
  background-color: #3c6d71;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  margin-top: 10px;
  align-self: center;
  width: 100%;
  max-width: 200px;
}

.form-container button:hover {
  background-color: #2a4d4f;
}