/* Footer.css */

.footer {
    background-color: #222;
    color: #f9fafc;
    padding: 20px 0;
    text-align: center;
    font-family: 'Droid Arabic Kufi', sans-serif;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .footer-section {
    margin: 20px;
    max-width: 300px;
  }
  
  .footer-section h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .footer-section p {
    font-size: 16px;
  }
  
  .footer-bottom {
    margin-top: 20px;
    border-top: 1px solid #444;
    padding-top: 10px;
  }  