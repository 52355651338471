.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  color: black;
  margin: 0.5% 3% 0 0;
}

.burger-menu {
  cursor: pointer;
  font-size: 1.5rem;
  order: 1;
  margin: 0 0 0 5%;
}

.navbar-brand {
  order: 3;
  margin-left: auto;
}

.nav-items {
  display: none;
  order: 2;
  list-style: none;
  display: flex;
  gap: 20px;
  align-items: center;
  margin-left: auto;
}

.nav-items li {
  margin: 0;
}

.nav-items a {
  color: black;
  text-decoration: none;
  padding: 0.5rem 1rem;
  transition: background 0.3s, color 0.3s;
}

.nav-items a:hover {
  background: #2b5053;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .nav-items {
    display: none;
  }
}

@media (min-width: 769px) {
  .nav-items {
    display: flex;
  }

  .burger-menu {
    display: none;
  }
}

.navbar img {
  width: 100px;
  height: auto;
  max-width: 100%;
}

@media screen and (max-device-width: 480px) and (orientation: portrait) {
  .navbar img {
    width: 80px;
  }
}

@media screen and (max-device-width: 736px) and (orientation: landscape) {
  .navbar img {
    width: 80px;
  }
}